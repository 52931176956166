import React, { useState } from 'react';
import Image from 'next/image'
import Page from 'components/Layout/Page';
import { Flex, Heading, Button, VisibilityOn, VisibilityOff } from '@thenexlabs/uikit'
import jwt from 'jsonwebtoken';
import { useRouter } from "next/router";
import useAuth from 'hooks/useAuth'
import Link from 'next/link';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    margin: '10px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    margin: '10px',
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
  },
  forgotPassword: {
    color: 'limegreen',
    fontSize: '10px',
  },
  authButton: {
    borderRadius: '25px',
    cursor: 'pointer',
  },
  googleButton: {
    // Add any additional styles specific to the Google button
  },
  gitHubButton: {
    backgroundColor: 'white',
    border: '1px solid #D1D5DB', // Equivalent to border-gray-300 in Tailwind
    padding: '0.5rem 0.75rem', // Equivalent to py-2 px-3 in Tailwind
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    fontSize: '15px',
  },
  authContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  signInWithGitHub: {
    marginLeft: '0.5rem',
  },
};

export const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loginFailure, setLoginFailure] = useState(false)
  const router = useRouter()
  const { signIn } = useAuth();


  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleClickShowPassword = (event) => {
    event.preventDefault()
    setShowPassword(!showPassword);
  };

  async function login(username, password) {
    const response = await fetch(process.env.NEXT_PUBLIC_API + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });
    const data = await response.json();
    return data;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Submit form
    const response = await login(username, password);
    // console.log(response)
    if (response.success) {
      // Store JWT token in local storage
      localStorage.setItem('userToken', response.token);
      // router.back();
      const user = jwt.decode(response.token);
      // console.log(user)
      if(user.subscriptions && user.subscriptions.length>0){ // has a sub
        router.push('/profile/subscriptions');
      }else{ // has no sub
        router.push('/security/subscribe');
      }
    }else{
      setLoginFailure(true)
    }
  }

  return (
    <>
      <Page style={styles.container}>
        <Heading 
          scale="lg" 
          color="secondary" 
          mt="24px"
          mb="24px" 
          style={{textAlign: 'center'}}
        >
          {'Sign In'}
        </Heading>
        {loginFailure && 
          <Heading
            scale="md" 
            color="failure" 
            mt="24px"
            mb="24px" 
            style={{textAlign: 'center', margin: '25px 150px'}}
          >
            (Login Error) Please double check your username/password or create an account if you have not.
          </Heading>
        }
        <form style={styles.form} onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={handleUsernameChange}
            style={styles.input}
          />
          <Flex style={{ marginLeft: '-15px' }}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              style={styles.input}
            />
            <Button 
              style={{margin: 'auto', marginLeft: '-45px', height: '25px', padding: '5px'}} 
              onClick={handleClickShowPassword}
            >
              {showPassword ? <VisibilityOff/> : <VisibilityOn/>}
            </Button>
          </Flex>
          <p style={styles.forgotPassword}> 
            <Link href="/forgot-password">Forgot your password?</Link>
          </p>
          <Button type="submit" style={styles.button}>Sign In</Button>
        </form>
        <button 
          style={{...styles.authButton, ...styles.googleButton}} 
          onClick={() => signIn('google', { callbackUrl: `${window.location.origin}/` })}
        >
          <img src="/images/signin-assets/google/neutral/web_neutral_rd_SI.svg" alt="Sign in with Google" width={200} height={300} />
        </button>
        {/*<div style={styles.authContainer}>
          <button 
            style={{...styles.authButton, ...styles.gitHubButton}} 
            onClick={() => signIn('github', { callbackUrl: `${window.location.origin}/` })}
          >
            <img src="/images/signin-assets/github/github-mark.svg" alt="Sign in with GitHub" width={30} height={30} />
            <span style={styles.signInWithGitHub}><b>Sign in with GitHub</b></span>
          </button>
        </div>*/}
        <div style={{ borderTop: "2px solid limegreen", width: "300px", margin: "20px auto" }} />
        <Button scale='lg' onClick={()=>{router.push('/register')}} style={styles.button}>Sign Up</Button>
      </Page>
    </>
  );
}

export default LoginPage